import React, { Component } from 'react';
import logo from '../logo.svg';

class Home extends Component {
    constructor(props) {
      super(props);
      this.state = {
        data: null,
      };
    }

    render() {
        return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                {this.state.data}
                </p>
                <a
                className="App-link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
                >
                Learn React
                </a>
            </header>
        </div>
        )
    }
}

export default Home;