import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Guest from './page/Guest';
import Home from './page/Home';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }
  componentDidMount() {
    fetch('https://jourj.kf-web.com/rest')
      .then(res => res.text())
      .then(
        (result) => {
          this.setState({
            data: result
          });
        }
      );
  }
  
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/guest/:code" children={<Guest />} />
        </Switch>
      </Router>
    );
  }
}

export default App;
