import React from 'react';
import { useParams } from "react-router-dom";

function Guest() {
    let { code } = useParams();

    return (
        <div> Guest ! CODE : {code}</div>
    )
}

export default Guest;